import React from "react"

const AmbassadorsStep3Icon = () => {
    return (
        <svg
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2995_61)">
                <path
                    d="M37.2883 58.105C39.2973 60.565 43.0283 62.492 48.0713 62.492C54.6313 62.492 58.8543 59.171 58.8543 54.456C58.8543 50.356 55.2463 48.183 52.4583 47.896C55.4923 47.363 58.4443 45.108 58.4443 41.664C58.4443 37.113 54.5083 34.243 48.1123 34.243C43.3153 34.243 39.8713 36.088 37.6573 38.589L40.5273 42.197C42.4543 40.352 44.8733 39.327 47.4973 39.327C50.3673 39.327 52.5813 40.434 52.5813 42.607C52.5813 44.657 50.5723 45.641 47.5383 45.641C46.5133 45.641 44.6273 45.641 44.1353 45.6V50.684C44.5453 50.643 46.3903 50.643 47.5383 50.643C51.3513 50.643 53.0323 51.668 53.0323 53.882C53.0323 55.973 51.1873 57.408 47.8663 57.408C45.2013 57.408 42.2083 56.26 40.3223 54.292L37.2883 58.105Z"
                    fill="#00A0F0"
                />
            </g>
            <path
                d="M16 27.5297V16.0008L27.5298 16.0008M27.5298 82H16.0001L16.0001 70.4704M70.4713 16.0001L81.9999 16V27.5296M82 70.4703V81.9993H70.4713"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter1_d_2995_61)">
                <circle
                    cx="49"
                    cy="49"
                    r="32.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2995_61"
                    x="27.2891"
                    y="24.243"
                    width="41.5664"
                    height="48.2489"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_61"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_61"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_2995_61"
                    x="0"
                    y="0"
                    width="98"
                    height="98"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_61"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_61"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default AmbassadorsStep3Icon

import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import AmbassadorsStep1Icon from "../../components/Ambassadors/AmbassadorsStep1Icon"
import AmbassadorsStep2Icon from "../../components/Ambassadors/AmbassadorsStep2Icon"
import AmbassadorsStep3Icon from "../../components/Ambassadors/AmbassadorsStep3Icon"
import Typography from "../../components/Typography"
import Button from "../../components/Button"
import ContentContainer from "../../templates/ContentContainer"

interface StepperTextProps {
    text: string
}
const StepperText: React.FC<StepperTextProps> = ({ text }) => {
    return (
        <Typography.H3 className="m-auto mt-[0] text-center max-w-[320px]">
            {text}
        </Typography.H3>
    )
}

const AmbassadorsSteps = () => {
    const { formatMessage } = useIntl()
    return (
        <section id="become-ambassador">
            <ContentContainer>
                <Typography.H2 className="text-center lg:text-left mb-3">
                    {formatMessage({
                        id: "ambassadors.steps.heading",
                    })}
                </Typography.H2>
                <Typography.Body1 className="text-center lg:text-left mb-12">
                    {formatMessage(
                        {
                            id: "ambassadors.steps.body",
                        },
                        { br: <br /> }
                    )}
                </Typography.Body1>

                {/* TODO: If we reuse this step component it might be a good idea to create a reusable component for it */}

                <div className="hidden lg:block mb-16">
                    <div className="grid grid-cols-3 mb-6">
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep1Icon />
                        </div>
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep2Icon />
                        </div>
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep3Icon />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 h-[1px] bg-white/50 justify-center mb-8">
                        <div className="h-[12px] w-[12px] rounded-full -mt-[6px] bg-links m-auto" />
                        <div className="h-[12px] w-[12px] rounded-full -mt-[6px] bg-links m-auto" />
                        <div className="h-[12px] w-[12px] rounded-full -mt-[6px] bg-links m-auto" />
                    </div>
                    <div className="grid grid-cols-3">
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step1",
                            })}
                        />
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step2",
                            })}
                        />
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step3",
                            })}
                        />
                    </div>
                </div>

                <div className="lg:hidden mb-16">
                    <div className="flex flex-col gap-2">
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep1Icon />
                        </div>
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step1",
                            })}
                        />
                    </div>
                    <div className="h-16 w-[1px] bg-white/50 m-auto mt-8 mb-5" />
                    <div className="flex flex-col gap-2">
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep2Icon />
                        </div>
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step2",
                            })}
                        />
                    </div>
                    <div className="h-16 w-[1px] bg-white/50 m-auto mt-8 mb-5" />
                    <div className="flex flex-col gap-2">
                        <div className="m-auto h-[98px] w-[98px] flex items-center justify-center">
                            <AmbassadorsStep3Icon />
                        </div>
                        <StepperText
                            text={formatMessage({
                                id: "ambassadors.steps.step3",
                            })}
                        />
                    </div>
                </div>

                <div className="text-center">
                    <Button
                        openNewTab
                        label={formatMessage({
                            id: "ambassadors.steps.cta.name",
                        })}
                        href={formatMessage({
                            id: "ambassadors.steps.cta.link",
                        })}
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default AmbassadorsSteps

import React from "react"

const AmbassadorsStep2Icon = () => {
    return (
        <svg
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2995_57)">
                <path
                    d="M38.135 62H58.717V56.875H47.319C54.658 51.709 58.512 47.65 58.512 42.976C58.512 37.523 53.879 34.243 48.057 34.243C44.244 34.243 40.185 35.637 37.52 38.712L40.841 42.566C42.686 40.639 45.064 39.409 48.139 39.409C50.435 39.409 52.608 40.598 52.608 42.976C52.608 46.338 49.328 49.167 38.135 57.408V62Z"
                    fill="#00A0F0"
                />
            </g>
            <path
                d="M16 27.5297V16.0008L27.5298 16.0008M27.5298 82H16.0001L16.0001 70.4704M70.4713 16.0001L81.9999 16V27.5296M82 70.4703V81.9993H70.4713"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter1_d_2995_57)">
                <circle
                    cx="49"
                    cy="49"
                    r="32.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2995_57"
                    x="27.5195"
                    y="24.243"
                    width="41.1992"
                    height="47.757"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_57"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_57"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_2995_57"
                    x="0"
                    y="0"
                    width="98"
                    height="98"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_57"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_57"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default AmbassadorsStep2Icon

import React from "react"
import PersonClickableTooltip from "../Person/PersonClickableTooltip"
import PersonPic from "../Person/PersonPic"
import Typography from "../Typography"

interface AmbassadorsCurrentAmbassadorsListItemProps {
    imgUrl: string
    name: string
    info: string
    infoCta: string
    hideAllTooltips: (number) => void
    indexOfThisListItem: number
    currentlyOpenTooltip: number
}

const AmbassadorsCurrentAmbassadorsListItem: React.FC<
    AmbassadorsCurrentAmbassadorsListItemProps
> = ({
    imgUrl,
    name,
    infoCta,
    info,
    hideAllTooltips,
    indexOfThisListItem,
    currentlyOpenTooltip,
}) => {
    return (
        <div className="text-center max-w-[252px] m-auto">
            <PersonPic
                size="100%"
                alt={name}
                imgUrl={imgUrl}
                withFrame
                withShadingLayer
            />
            <Typography.H3 className="mt-6 mb-1">{name}</Typography.H3>
            <PersonClickableTooltip
                ctaText={infoCta}
                hideAllTooltips={hideAllTooltips}
                indexOfThisListItem={indexOfThisListItem}
                currentlyOpenTooltip={currentlyOpenTooltip}
            >
                <Typography.H3 className="mb-2">{name}</Typography.H3>
                <Typography.Body1 className="break-words">
                    {info}
                </Typography.Body1>
            </PersonClickableTooltip>
        </div>
    )
}

export default AmbassadorsCurrentAmbassadorsListItem

import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import Button from "../../components/Button"
import Typography from "../../components/Typography"
import Frame from "../../components/Frame"
import groupImg from "../../assets/BackgroundImages/Ambassadors/Horizen_Ambassadors_group.png"

const AmbassadorsProgram = () => {
    const { formatMessage } = useIntl()
    return (
        <div>
            <ContentContainer>
                <div className="flex flex-col items-center lg:grid lg:grid-cols-[6fr,4fr] gap-14">
                    <div className="flex flex-col text-center lg:text-left lg:items-baseline justify-center ">
                        <Typography.H2 className="mb-6">
                            {formatMessage({
                                id: "ambassadors.ambassadorsProgram.heading",
                            })}
                        </Typography.H2>
                        <Typography.Body1 className="mb-6">
                            {formatMessage({
                                id: "ambassadors.ambassadorsProgram.body",
                            })}
                        </Typography.Body1>
                        <Button
                            label={formatMessage({
                                id: "ambassadors.ambassadorsProgram.cta.name",
                            })}
                            href={formatMessage({
                                id: "ambassadors.ambassadorsProgram.cta.link",
                            })}
                        />
                    </div>

                    <div>
                        <div className="sm:border-[1px] sm:border-white/50 sm:p-9">
                            <Frame>
                                <img
                                    src={groupImg}
                                    alt="ambassadors"
                                    className="w-full h-full p-[2px]"
                                />
                            </Frame>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </div>
    )
}

export default AmbassadorsProgram

import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import AmbassadorsCardsListItem from "../../components/Ambassadors/AmbassadorsCardsListItem"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"

const AmbassadorsCards = () => {
    const { formatMessage } = useIntl()

    // TODO: Create customIntl hook and add formatMessageOrUndefined
    const formatMessageOrUndefined = (id: string): string | undefined => {
        const NO_MATCHING_TEXT = "NO_MATCHING_TEXT"
        const formattedMsg = formatMessage({
            id,
            defaultMessage: NO_MATCHING_TEXT,
        })
        return formattedMsg === NO_MATCHING_TEXT ? undefined : formattedMsg
    }

    const renderCards = (): JSX.Element[] => {
        let index = 0
        const baseID = "ambassadors.cards.list"
        const components = []

        while (formatMessageOrUndefined(`${baseID}.${index}.heading`)) {
            components.push(
                <AmbassadorsCardsListItem
                    heading={formatMessageOrUndefined(
                        `${baseID}.${index}.heading`
                    )}
                    body={formatMessageOrUndefined(`${baseID}.${index}.body`)}
                    key={`${baseID}.${index}`}
                />
            )
            index += 1
        }

        return components
    }

    return (
        <ContentContainer>
            <Typography.H2 className="text-center mb-3">
                {formatMessage({
                    id: "ambassadors.cards.heading",
                })}
            </Typography.H2>
            <p className="font-proxima text-horizen-content-grey text-[14px] font-bold tracking-[0.2em] leading-[25px] uppercase text-center mb-6">
                {formatMessage({
                    id: "ambassadors.cards.subheading",
                })}
            </p>
            <Typography.Body1 className="text-center max-w-[736px] m-auto mb-10">
                {formatMessage({
                    id: "ambassadors.cards.body",
                })}
            </Typography.Body1>

            <div className="flex flex-col gap-6 md:grid md:grid-cols-2 auto-rows-[1fr]">
                {renderCards()}
            </div>
        </ContentContainer>
    )
}

export default AmbassadorsCards

import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import Brackets from "../../components/Brackets"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"

const AmbassadorsHashTag = () => {
    const { formatMessage } = useIntl()
    return (
        <ContentContainer>
            <Brackets concavity={14}>
                <div className="flex flex-col p-4 sm:p-7 lg:p-10 gap-4 md:gap-8 md:grid md:grid-cols-[4fr,6fr]">
                    <div className="flex flex-col">
                        <Typography.H2 className="mb-3">
                            {formatMessage({
                                id: "ambassadors.brackets.heading",
                            })}
                        </Typography.H2>
                        <Typography.H3>
                            {formatMessage({
                                id: "ambassadors.brackets.subheading",
                            })}
                        </Typography.H3>
                    </div>
                    <Typography.Body1>
                        {formatMessage({
                            id: "ambassadors.brackets.body",
                        })}
                    </Typography.Body1>
                </div>
            </Brackets>
        </ContentContainer>
    )
}

export default AmbassadorsHashTag

import React from "react"

interface PersonBadgeNoFrameProps {
    size?: string
}

const PersonBadgeNoFrame: React.FC<PersonBadgeNoFrameProps> = ({
    size = 252,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 252 252"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="126" cy="126" r="98" fill="#0E9DE5" />
            <g filter="url(#filter0_d_2023_2094)">
                <circle
                    cx="126"
                    cy="126"
                    r="109.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2023_2094"
                    x="0"
                    y="0.000244141"
                    width="252"
                    height="252"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2023_2094"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2023_2094"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default PersonBadgeNoFrame

import React from "react"
import BoxWithLightOnTheBorder from "../BoxWithLightOnTheBorder"
import Typography from "../Typography"

interface AmbassadorsCardsListItemProps {
    heading: string
    body: string
}

const AmbassadorsCardsListItem: React.FC<AmbassadorsCardsListItemProps> = ({
    heading,
    body,
}) => {
    return (
        <BoxWithLightOnTheBorder>
            <Typography.H3 className="mb-3">{heading}</Typography.H3>
            <Typography.Body1>{body}</Typography.Body1>
        </BoxWithLightOnTheBorder>
    )
}

export default AmbassadorsCardsListItem

import React from "react"

interface FrameProps {
    size?: number
    stroke?: number
    color?: string
    showOnMobile?: boolean
}

const Frame: React.FC<FrameProps> = ({
    children,
    size = 20,
    color = "white",
    stroke = 4,
    showOnMobile = true,
}) => {
    const commonStyling = {
        height: `${size}px`,
        width: `${size}px`,
        borderWidth: `${stroke}px`,
        borderColor: color,
    }
    return (
        <div>
            <div className="relative">
                {children}
                <div
                    className={`absolute border top-0 left-0 ${
                        showOnMobile ? "" : "hidden md:block"
                    }`}
                    style={{
                        ...commonStyling,
                        borderRight: 0,
                        borderBottom: 0,
                    }}
                />
                <div
                    className={`absolute border top-0 right-0 ${
                        showOnMobile ? "" : "hidden md:block"
                    }`}
                    style={{
                        ...commonStyling,
                        borderLeft: 0,
                        borderBottom: 0,
                    }}
                />
                <div
                    className={`absolute border bottom-0 left-0  ${
                        showOnMobile ? "" : "hidden md:block"
                    }`}
                    style={{
                        ...commonStyling,
                        borderTop: 0,
                        borderRight: 0,
                    }}
                />
                <div
                    className={`absolute border bottom-0 right-0  ${
                        showOnMobile ? "" : "hidden md:block"
                    }`}
                    style={{
                        ...commonStyling,
                        borderTop: 0,
                        borderLeft: 0,
                    }}
                />
            </div>
        </div>
    )
}

export default Frame

import React, { useCallback, useLayoutEffect, useRef } from "react"
import MinusIcon from "../MinusIcon"
import Typography from "../Typography"

interface PersonClickableTooltip {
    ctaText: string
    hideAllTooltips: (number) => void
    indexOfThisListItem: number
    currentlyOpenTooltip: number
    children: React.ReactNode
}

const PersonClickableTooltip: React.FC<PersonClickableTooltip> = ({
    ctaText,
    hideAllTooltips,
    indexOfThisListItem,
    currentlyOpenTooltip,
    children,
}) => {
    const tooltipRef = useRef(null)

    const moveTooltipWithinClientBounds = useCallback(() => {
        const { x, width } = tooltipRef.current.getBoundingClientRect()

        let xOffset = 0
        if (x < 0) xOffset = Math.abs(x)
        if (x + width > window.innerWidth)
            xOffset = window.innerWidth - (x + width)

        tooltipRef.current.style.transform = `translateX(${
            -(width / 2) + xOffset
        }px)`
    }, [])

    useLayoutEffect(() => {
        moveTooltipWithinClientBounds()
    }, [tooltipRef, moveTooltipWithinClientBounds])

    return (
        <div className="relative">
            <Typography.Link1
                onClick={() => {
                    hideAllTooltips(indexOfThisListItem)
                }}
                showBrackets
            >
                {ctaText}
            </Typography.Link1>
            <div
                className="absolute px-6 first-letter:first-line:transition-opacity duration-500 left-1/2 -translate-x-1/2 top-9 w-[min(calc(100vw),350px)]"
                style={{
                    // if the current/open tooltip matches this index, show it
                    opacity:
                        currentlyOpenTooltip === indexOfThisListItem
                            ? "1"
                            : "0",
                    zIndex:
                        currentlyOpenTooltip === indexOfThisListItem
                            ? "10"
                            : "-10",
                }}
                ref={tooltipRef}
            >
                <div className="relative border px-5 py-6 bg-horizen-hp-bg/90 backdrop-blur-[4px] text-left">
                    <div>{children}</div>
                    <div
                        className="absolute top-[-12.5px] right-[-12.5px]"
                        onClick={() => hideAllTooltips(-1)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => hideAllTooltips(-1)}
                    >
                        <MinusIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonClickableTooltip

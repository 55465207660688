import React from "react"

interface PersonBadgeProps {
    size?: string
}
const PersonBadge: React.FC<PersonBadgeProps> = ({ size = 252 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 252 252"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.9385 35.0439V16.9427L35.0424 16.9427M35.0424 235.061H16.9389L16.9389 216.958M216.956 16.9403L235.056 16.9399V35.0436M235.056 216.957V235.059H216.956"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter0_d_1515_493)">
                <circle cx="126" cy="126" r="98" fill="#0E9DE5" />
            </g>

            <g filter="url(#filter1_d_1515_493)">
                <circle
                    cx="126"
                    cy="126"
                    r="109.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1515_493"
                    x="12"
                    y="12"
                    width="228"
                    height="228"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1515_493"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1515_493"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_1515_493"
                    x="0"
                    y="0"
                    width="252"
                    height="252"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1515_493"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1515_493"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default PersonBadge

import { useIntl } from "gatsby-plugin-intl"
import React, { useState } from "react"
import AmbassadorsCurrentAmbassadorsListItem from "../../components/Ambassadors/AmbassadorsCurrentAmbassadorsListItem"
import Button from "../../components/Button"
import Typography from "../../components/Typography"
import ContentContainer from "../../templates/ContentContainer"

const AmbassadorsCurrentAmbassadors = () => {
    const { formatMessage } = useIntl()

    // TODO: Create customIntl hook and add formatMessageOrUndefined
    const formatMessageOrUndefined = (id: string): string | undefined => {
        const NO_MATCHING_TEXT = "NO_MATCHING_TEXT"
        const formattedMsg = formatMessage({
            id,
            defaultMessage: NO_MATCHING_TEXT,
        })
        return formattedMsg === NO_MATCHING_TEXT ? undefined : formattedMsg
    }

    /**
     * current: number
     * The id of the currently open tooltip
     * Passed to AmbassadorsCurrentAmbassadorsListItem -> PersonClickableTooltip
     */
    const [currentlyOpenTooltip, changeCurrent] = useState(-1)

    /**
     * Changes @var current to the id passed from PersonClickableTooltip onClick
     * Implicitly "hides" all other tooltips
     * @param index: number
     */
    const hideAllTooltipsExceptIndexProvided = (index: number) => {
        changeCurrent(index)
    }

    const renderAmbassadors = (): JSX.Element[] => {
        let index = 0
        const baseID = "ambassadors.list"
        const elements: JSX.Element[] = []

        while (formatMessageOrUndefined(`${baseID}.${index}.name`)) {
            elements.push(
                <AmbassadorsCurrentAmbassadorsListItem
                    name={formatMessage({
                        id: `${baseID}.${index}.name`,
                    })}
                    info={formatMessage({
                        id: `${baseID}.${index}.info`,
                    })}
                    imgUrl={formatMessage({
                        id: `${baseID}.${index}.imgUrl`,
                    })}
                    infoCta={formatMessage({
                        id: `ambassadors.currentEmbassadors.contactText`,
                    })}
                    currentlyOpenTooltip={currentlyOpenTooltip} // id of the open tooltip, if set
                    indexOfThisListItem={index} // id of this item
                    hideAllTooltips={hideAllTooltipsExceptIndexProvided}
                    key={`${baseID}.${index}.name`}
                />
            )
            index += 1
        }

        return elements
    }

    return (
        <ContentContainer>
            <Typography.H2 className="mb-16 text-center lg:text-left">
                {formatMessage({
                    id: "ambassadors.currentEmbassadors.heading",
                })}
            </Typography.H2>

            <div className="mb-24 grid-cols-2 grid md:grid-cols-4 lg:grid-cols-5 gap-x-12 gap-y-16">
                {renderAmbassadors()}
            </div>

            <div className="flex justify-center">
                <Button
                    openNewTab
                    label={formatMessage({
                        id: "ambassadors.currentEmbassadors.cta.name",
                    })}
                    href={formatMessage({
                        id: "ambassadors.currentEmbassadors.cta.link",
                    })}
                />
            </div>
        </ContentContainer>
    )
}

export default AmbassadorsCurrentAmbassadors

import React from "react"
import Frame from "../Frame"
import PersonPic from "../Person/PersonPic"
import Typography from "../Typography"

interface AmbassadorsTestimonialsListItemProps {
    imgUrl: string
    heading: string
    body: string
}

const AmbassadorsTestimonialsListItem: React.FC<
    AmbassadorsTestimonialsListItemProps
> = ({ imgUrl, heading, body }) => {
    return (
        <div className="mb-24 last-of-type:mb-0">
            <Frame stroke={1} color="rgb(255 255 255 / 0.5)">
                <div className="flex flex-col text-center px-6 py-6 gap-3 md:flex-row md:text-left md:flex md:gap-16 md:py-0 md:pl-0 md:pr-10 items-center">
                    <div className="shrink-0">
                        <PersonPic
                            imgUrl={imgUrl}
                            alt={heading}
                            withFrame={false}
                            withShadingLayer
                        />
                    </div>
                    <div>
                        <Typography.H3 className="mb-4">
                            {heading}
                        </Typography.H3>
                        <Typography.Body1>{`"${body}"`}</Typography.Body1>
                    </div>
                </div>
            </Frame>
        </div>
    )
}

export default AmbassadorsTestimonialsListItem

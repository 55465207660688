import React from "react"
import PersonBadge from "./PersonBadge"
import PersonBadgeNoFrame from "./PersonBadgeNoFrame"

interface PersonPicProps {
    imgUrl: string
    alt: string
    withFrame?: boolean
    withShadingLayer?: boolean
    size?: string
}

const PersonPic: React.FC<PersonPicProps> = ({
    imgUrl,
    alt,
    withFrame = true,
    withShadingLayer = true,
    size = "252px",
}) => {
    return (
        <div
            style={{
                width: size,
            }}
        >
            <div className="relative flex">
                {withFrame ? (
                    <PersonBadge size="100%" />
                ) : (
                    <PersonBadgeNoFrame size="100%" />
                )}
                <div
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 m-auto rounded-full overflow-hidden"
                    style={{ width: "74%" }}
                >
                    <img src={imgUrl} alt={alt} className="h-full w-full" />
                    {withShadingLayer && (
                        <>
                            <div className="bg-[#C4C4C4] mix-blend-color h-full w-full top-0 left-0 absolute" />
                            <div className="bg-[rgba(50,115,226,0.15)] mix-blend-color h-full w-full top-0 left-0 absolute" />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PersonPic

import React from "react"

const AmbassadorsStep1Icon = () => {
    return (
        <svg
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2995_50)">
                <path
                    d="M46.3948 62H52.2168V34.653H47.1328L38.5228 43.304L41.8438 46.789L46.3948 42.156V62Z"
                    fill="#00A0F0"
                />
            </g>
            <path
                d="M16 27.5297V16.0008L27.5298 16.0008M27.5298 82H16.0001L16.0001 70.4704M70.4713 16.0001L81.9999 16V27.5296M82 70.4703V81.9993H70.4713"
                stroke="white"
                strokeWidth="0.5"
            />
            <g filter="url(#filter1_d_2995_50)">
                <circle
                    cx="49"
                    cy="49"
                    r="32.5"
                    stroke="white"
                    strokeDasharray="5 5"
                    shapeRendering="crispEdges"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2995_50"
                    x="28.5234"
                    y="24.653"
                    width="33.6914"
                    height="47.347"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_50"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_50"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_2995_50"
                    x="0"
                    y="0"
                    width="98"
                    height="98"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.626318 0 0 0 0 0.941667 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2995_50"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2995_50"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default AmbassadorsStep1Icon

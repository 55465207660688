import * as React from "react"

const MinusIcon = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12.5"
                cy="12.5"
                r="11"
                fill="#0E9DE5"
                stroke="#0C0C1C"
                strokeWidth="3"
            />
            <rect
                x="18"
                y="11"
                width="3"
                height="11"
                transform="rotate(90 18 11)"
                fill="white"
            />
        </svg>
    )
}

export default MinusIcon

import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import AmbassadorsTestimonialsListItem from "../../components/Ambassadors/AmbassadorsTestimonialsListItem"
import ContentContainer from "../../templates/ContentContainer"

const AmbassadorsTestimonials = () => {
    const { formatMessage } = useIntl()

    // TODO: Create customIntl hook and add formatMessageOrUndefined
    const formatMessageOrUndefined = (id: string): string | undefined => {
        const NO_MATCHING_TEXT = "NO_MATCHING_TEXT"
        const formattedMsg = formatMessage({
            id,
            defaultMessage: NO_MATCHING_TEXT,
        })
        return formattedMsg === NO_MATCHING_TEXT ? undefined : formattedMsg
    }

    const renderTestimonials = (): JSX.Element[] => {
        let index = 0
        const baseID = "ambassadors.list"
        const elements: JSX.Element[] = []
        const MAX_TESTIMONIALS = 3
        while (
            formatMessageOrUndefined(`${baseID}.${index}.name`) &&
            index < MAX_TESTIMONIALS
        ) {
            elements.push(
                <AmbassadorsTestimonialsListItem
                    heading={formatMessage({
                        id: `${baseID}.${index}.name`,
                    })}
                    body={formatMessage({
                        id: `${baseID}.${index}.info`,
                    })}
                    imgUrl={formatMessage({
                        id: `${baseID}.${index}.imgUrl`,
                    })}
                    key={`${baseID}.${index}.name`}
                />
            )
            index += 1
        }

        return elements
    }

    return <ContentContainer>{renderTestimonials()}</ContentContainer>
}

export default AmbassadorsTestimonials

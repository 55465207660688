import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import NormalPageHero from "../../components/NormalPageHero"
import * as styles from "../../styles/ambassadors/ambassadors.module.css"

const AmbassadorsHero = () => {
    const { formatMessage } = useIntl()

    return (
        <section
            className={`pt-[9rem] md:pt-[calc(4rem+150px)] ${styles.ambassadorsHero}`}
        >
            <NormalPageHero
                titleKey={formatMessage({ id: "ambassadors.hero.heading" })}
                subtitleKey={formatMessage({ id: "ambassadors.hero.bodyText" })}
            />
        </section>
    )
}

export default AmbassadorsHero

import React from "react"
import AmbassadorsHero from "../sections/ambassadors/AmbassadorsHero"
import AmbassadorsProgram from "../sections/ambassadors/AmbassadorsProgram"
import AmbassadorsHashTag from "../sections/ambassadors/AmbassadorsHashTag"
import NewsletterForm from "../components/NewsletterForm"
import Layout from "../templates/Layout"
import AmbassadorsCards from "../sections/ambassadors/AmbassadorsCards"
import AmbassadorsSteps from "../sections/ambassadors/AmbassadorsSteps"
import AmbassadorsTestimonials from "../sections/ambassadors/AmbassadorsTestimonials"
import AmbassadorsCurrentAmbassadors from "../sections/ambassadors/AmbassadorsCurrentAmbassadors"
import * as style from "../styles/ambassadors/ambassadors.module.css"
import cardsStepsTestimonialsSectionBG from "../assets/BackgroundImages/Ambassadors/Horizen_Ambassadors_main2.png"
import AmbassadorsHelmet from "../components/SEO/AmbassadorsHelmet"

const ambassadors = () => {
    return (
        <div className="overflow-hidden">
            <Layout>
                <div className="bg-horizen-hp-bg">
                    <AmbassadorsHelmet />
                    <AmbassadorsHero />
                    <div className="pb-20 lg:pb-40">
                        <AmbassadorsProgram />
                    </div>
                    <div className="pb-20 lg:pb-40">
                        <AmbassadorsHashTag />
                    </div>
                    <div
                        className="bg-no-repeat lg:bg-contain lg:bg-right"
                        style={{
                            backgroundImage: `url(${cardsStepsTestimonialsSectionBG})`,
                        }}
                    >
                        <div className="pb-20 lg:pb-40">
                            <AmbassadorsCards />
                        </div>
                        <div className="pb-20 lg:pb-40">
                            <AmbassadorsSteps />
                        </div>
                        <div className="pb-20 lg:pb-40">
                            <AmbassadorsTestimonials />
                        </div>
                    </div>
                    <div
                        className={`pb-20 lg:pb-40 ${style.currentAmbassadorsSection}`}
                    >
                        <AmbassadorsCurrentAmbassadors />
                    </div>
                    <NewsletterForm />
                </div>
            </Layout>
        </div>
    )
}

export default ambassadors

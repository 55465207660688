import React from "react"

interface BracketsProps {
    children?: React.ReactNode
    stroke?: number
    concavity?: number
    color?: string
    className?: string
}

const Brackets: React.FC<BracketsProps> = ({
    children,
    className,
    color = "white",
    stroke = 1,
    concavity = 6,
}) => {
    const commonBracketStyle = {
        width: `${concavity}px`,
        borderWidth: stroke,
        borderColor: color,
    }

    return (
        <div className={`flex ${className || ""}`}>
            <div
                className="shrink-0 border"
                style={{
                    ...commonBracketStyle,
                    borderRight: "none",
                }}
            />
            {children}
            <div
                className="shrink-0 border"
                style={{
                    ...commonBracketStyle,
                    borderLeft: "none",
                }}
            />
        </div>
    )
}

export default Brackets
